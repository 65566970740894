export const uiuxPortfolio = [
    {
        id: 1,
        title: "Fooya!",
        img: "assets/FooyaSquare.jpeg",
        a: "http://addisonviener.com/fooya/"
    },
    {
        id: 2,
        title: "Ember",
        img: "assets/Emberlogo.jpeg",
        a: "http://addisonviener.com/ember/"
    },
    {
        id: 3,
        title: "Mvents",
        img: "assets/mvents.jpg",
        a: "http://addisonviener.com/mvents/"
    },
]

export const websitesPortfolio = [
    {
        id: 1,
        title: "MaximizeHQ",
        img: "assets/maximize.png",
        a: "https://www.maximizehq.org/"
    },
    {
        id: 2,
        title: "Michigan Entrepreneur Summit",
        img: "assets/mes.png",
        a: "https://michiganentrepreneursummit.com/"
    },
    {
        id: 3,
        title: "My Natural Doctor",
        img: "assets/mynaturaldr.png",
        a: "https://www.mynaturaldr.com/"
    },
    {
        id: 4,
        title: "Yazing",
        img: "assets/yazing.png",
        a: "https://yazing.com/"
    }
]

export const graphicDesginPortfolio = [
    {
        id: 1,
        title: "GoaldenRoad Logo",
        img: "assets/design/goaldenroad.png",
        a: "assets/design/goaldenroad.png"
    },
    {
        id: 2,
        title: "Maximize Logo",
        img: "assets/design/maximize.png",
        a: "assets/design/maximize.png"
    },
    {
        id: 3,
        title: "Maximize Metamorphosis Icons",
        img: "assets/design/maximizemeta.png",
        a: "assets/design/maximizemeta.png"
    },  
    {
        id: 4,
        title: "My Natural Doctor Logo",
        img: "assets/design/mynaturaldr.jpeg",
        a: "assets/design/mynaturaldr.jpeg"
    },
    {
        id: 5,
        title: "Sweet Caroline Logo",
        img: "assets/design/seetcaroline.jpeg",
        a: "assets/design/seetcaroline.jpeg"
    },
    {
        id: 6,
        title: "Healthy Living",
        img: "assets/design/healthy.png",
        a: "assets/design/healthy.png"
    },
    {
        id: 7,
        title: "Lacrosse Book Location",
        img: "assets/design/booklocation.png",
        a: "assets/design/booklocation.png"
    },
    {
        id: 8,
        title: "Sailboat Icon",
        img: "assets/design/sail_boat_icon.png",
        a: "assets/design/sail_boat_icon.png"
    },
    {
        id: 9,
        title: "Teslsa Model Y Icon",
        img: "assets/design/tesla.png",
        a: "assets/design/tesla.png"
    },
    {
        id: 10,
        title: "Artichoke Icon",
        img: "assets/design/artichoke.png",
        a: "assets/design/artichoke.png"
    },
    {
        id: 11,
        title: "Zombie",
        img: "assets/design/zombie2.png",
        a: "assets/design/zombie2.png"
    },
    {
        id: 12,
        title: "Multi-Media Self Portriat",
        img: "assets/design/multimedia.jpg",
        a: "assets/design/multimedia.jpg"
    },
]

export const artworkPortfolio = [
    {
        id: 1,
        title: "Charcoal Self Portriat (College)",
        img: "assets/artwork/selfportrait-1.png",
        a: "assets/artwork/selfportrait-1.png"
    },
    {
        id: 2,
        title: "Chiaroscuro Still Life",
        img: "assets/artwork/stilllife.png",
        a: "assets/artwork/stilllife.png"
    },
    {
        id: 3,
        title: "Graphite Tricycle Drawing",
        img: "assets/artwork/BikeDrawing.png",
        a: "assets/artwork/BikeDrawing.png"
    },
    {
        id: 4,
        title: "Contrast Pen Drawing",
        img: "assets/artwork/contrastproject.png",
        a: "assets/artwork/contrastproject.png"
    },
    {
        id: 5,
        title: "Charcoal Portriat",
        img: "assets/artwork/charcoalportrait.png",
        a: "assets/artwork/charcoalportrait.png"
    },
    {
        id: 6,
        title: "Skeleton Pen Drawing",
        img: "assets/artwork/Skelton-scaled.jpeg",
        a: "assets/artwork/Skelton-scaled.jpeg"
    },
    {
        id: 7,
        title: "Magazine Cutting Collage",
        img: "assets/artwork/magazine.png",
        a: "assets/artwork/magazine.png"
    },
    {
        id: 8,
        title: "Charcoal Self Portriat (Highschool)",
        img: "assets/artwork/SelfPortiat.png",
        a: "assets/artwork/SelfPortiat.png"
    },
]

export const videographyPortfolio = [
    {
        id: 1,
        title: "Michigan Entreprenur Summit",
        img: "assets/Welcome.png",
        a: "https://www.youtube.com/watch?v=rxHHmUjbqQM"
    },
    {
        id: 2,
        title: "SwiftShip Demo",
        img: "assets/swiftship.png",
        a: "https://www.youtube.com/watch?v=0lRxZ7VYk7o"
    },
    {
        id: 3,
        title: "Pandanormal Activity (Stop Motion Animation)",
        img: "assets/pandanormal.png",
        a: "https://www.youtube.com/watch?v=Okwg2Mwo-7U"
    }
]

export const photographyPorfolio = [
    {
        id: 1,
        title: "Eagle",
        img: "assets/photos/eagle.jpeg",
        a: "assets/photos/eagle.jpeg"
    },
    {
        id: 2,
        title: "Egret",
        img: "assets/photos/pojacbird.jpeg",
        a: "assets/photos/pojacbird.jpeg"
    },
    {
        id: 3,
        title: "Frog",
        img: "assets/photos/frog.jpeg",
        a: "assets/photos/frog.jpeg"
    },
    {
        id: 4,
        title: "Lily Pads",
        img: "assets/photos/lilypad.jpeg",
        a: "assets/photos/lilypad.jpeg"
    },
    {
        id: 5,
        title: "Owl in the Light",
        img: "assets/photos/owl.jpeg",
        a: "assets/photos/owl.jpeg"
    },
    {
        id: 6,
        title: "Fly",
        img: "assets/photos/fly.jpeg",
        a: "assets/photos/fly.jpeg"
    },
    {
        id: 7,
        title: "Bird in Cave Entrance",
        img: "assets/photos/cavebird.jpeg",
        a: "assets/photos/cavebird.jpeg"
    },
    {
        id: 8,
        title: "Bat in the Moon",
        img: "assets/photos/moonbat.jpeg",
        a: "assets/photos/moonbat.jpeg"
    },
    {
        id: 9,
        title: "Moon with Clouds",
        img: "assets/photos/moon2.jpeg",
        a: "assets/photos/moon2.jpeg"
    },
    {
        id: 10,
        title: "Clouds",
        img: "assets/photos/clouds.jpeg",
        a: "assets/photos/clouds.jpeg"
    },
    {
        id: 11,
        title: "Contrast Tree Branches",
        img: "assets/photos/treebranches.jpeg",
        a: "assets/photos/treebranches.jpeg"
    },
    {
        id: 12,
        title: "American Flag in the Woods",
        img: "assets/photos/flag.jpeg",
        a: "assets/photos/flag.jpeg"
    }
]
